.is_home{
    .cma-banner{
        &_caption{
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            em{
                font-style: normal;
            }
            h1{
                margin-right: auto;
                font-size: 12px;
                margin-top: 55px;
                @include media-breakpoint-up(md){
                    margin-top: 100px;
                    margin-left: 5.3%;
    
                }
            }
        }
        .cma-actions{
            margin-top: auto;
            margin-bottom: 50px;
            @include media-breakpoint-up(xl){
                margin-bottom: 100px;
            }
        }
        .cma-rotaing{
            color:$brand-light;
            font-size: 18px;
            text-align: center;
            min-height: 100px;
            @include media-breakpoint-up(lg){
                padding-left: 25%;
                padding-right: 25%;
                font-size: 20px;
            }
        }
    }

    .cma-banner_sub{
        font-size: 26px;
        font-style: italic;
        @include media-breakpoint-up(md){
            font-size: 36px;
        }
    }
    .cma-actions a{
        @include media-breakpoint-down(md){
            margin-bottom: 30px;
        }
    }
    .cma-expand{
        .heading2{
            margin-bottom: 30px;
            .subHeading2{
                display: block;
                font-size: 26px;
                font-weight: 300;
                @include media-breakpoint-up(md){
                    font-size: 26px;
                }
            }
        }
        &_customers{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding-top: 80px;
            img{
                margin: 0 17px 35px;
                @include media-breakpoint-up(md){
                    margin: 0 30px 35px;
                }
            }
        }
    }
    .cma-insight{
        font-size: 20px;
        margin-bottom: 50px;
        @include media-breakpoint-up(md){
            margin-bottom: 90px;
        }
    }
    .cma-featured{
        background: $brand-dark;
        &_title{
            @include media-breakpoint-up(md){
                padding-bottom: 100px;
            }
            .heading2{
                margin-bottom: 10px;
            }
        }
        &_wrap{
            img{
                @include media-breakpoint-up(lg){
                    width: 100%;
                }
            }
        }
    }
    .cma-services{
        .imgNoFluid{
            @include media-breakpoint-down(sm){
                max-width: 100%;
                height: auto;
            }
            @include media-breakpoint-up(lg){
                margin-left: -128px;
            }
        }
        .cma-icon{
            width: 12px;
            display: block;
            transition: transform 250ms ease;
            margin-top: auto;
            svg path{
                transition: fill 250ms ease;
            }
        }
        &_links{
            @include media-breakpoint-up(lg){
                padding-top: 8%;
            }
        }
        &_link{
            color:$brand-title;
            padding: 30px;
            transition: box-shadow 250ms ease;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            h2{
                color:$brand-title;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 25px;
                @include media-breakpoint-up(md){
                    font-size: 20px;
                }
            }
            p{
                strong{
                    color:#eabb00;
                    display: block;
                }
                line-height: 1.5;
            }
            &:hover{
                box-shadow: 0px 11px 35px 0 rgba(0, 0, 0, 0.15);
                .cma-icon{
                    transform: translateX(10px);
                    svg path{
                        fill: $brand-secondary;
                    }
                }
            }
        }
    }
}

.cma-power{
    padding-top: 90px;
    padding-bottom: 0;
    position: relative;
    &::after{
        background-color: #f5f2e7;
        content: "";
        height: 55%;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    .cma-secTitle{
        padding-bottom: 0;
        .heading2{
            margin-bottom: 0;
        }
    }
    .cma-somos{
        font-size: 18px;
        margin-bottom: 120px;
    }
    .cma-comunidad {
        &_somos{
            font-size: 18px;
            margin-bottom: 70px;
        }
    }
    &_items{
        margin-bottom: 70px;
    }
    &_item{
        font-size: 18px;
        padding: 70px 30px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        &:nth-child(2){
            background: $brand-secondary;
            @include media-breakpoint-up(md){
                top: -50px;
            }
        }
        &:nth-child(1),
        &:nth-child(3){
            background: $brand-title;
            color:$brand-light;
        }
        p{
            margin:0;
        }
        .cma-icon{
            display: inline-block;
            width: 50px;
        }
    }
}

.cma-comunidad{
    span{
        display: block;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
    }
}
.cma-somos_ex{
    .heading2{
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}
.cma-somos_big{
    color: rgba(236, 231, 215, 0.6);
    font-family: $fontTitle;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    @include media-breakpoint-up(xl){
        font-size: 120px;
    }
    @include media-breakpoint-up(xxl){
        font-size: 126px;
    }
}

.cma-agency{
    background: url('/images/branding-marketing-colmena.webp') #201f1f no-repeat center right;
    .cma-services_left img{
        @include media-breakpoint-down(md){
            margin-bottom: 50px;
        }
    }
}

.cma-agency_grid{
    display: inline-grid;
    grid-template-columns: repeat(2,minmax(50%,1fr));
    grid-auto-rows: 1fr;
    grid-auto-flow: dense;
    align-self: center;
    .gridItem{
        border:1px solid $brand-secondary;
        color:$brand-light;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 33px 10px;
        &:nth-child(1){
            border: none;
            figure{
                margin: 0;
            }
        }
        &:nth-child(2){
            margin-left: -1px;
        }
        &:nth-child(3){
            border-bottom: none;
        }
        &:nth-child(4){
            grid-row: span 2;
            border-left: none;
            border-top: none;
            margin-left: -1px;
            figure{
                margin: 5px 0;
                min-width: 70px;
                &:first-child{
                    margin-top: 20px;
                }
            }
        }
        span{
            font-family: $fontTitle;
            font-size: 28px;
            font-weight: bold;
            @include media-breakpoint-up(md){
                font-size: 33px;
            }
        }
    }
}