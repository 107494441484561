.Video-box {

    position: fixed;
    width: 100%;
    @include centrar;
    max-width: 1000px;
    z-index: 17;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;

    @media (min-width:1600px) {
        max-width: 1400px;
    }

    @media (max-height:900px) {
        top: 70px;
        transform: translate(-50%,0);
    }

    &.showme,
    &.showme~.OverlayModal,
    &.showme~.video-disclaimer-box {
        opacity: 1;
        visibility: visible;
    }

    .Video-data {
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        overflow: hidden;
    }

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .videoLink {
        margin: 10px auto 0;
        position: absolute;
        left: 0;
        right: 0;
        width: 180px;
    }
}

.video_local{
    display: block;
    width: 95%;
    margin: 0 auto;
    &:focus{
        outline: none;
    }
}

[data-videoplayicon]{
    cursor: pointer;
    .icon-play{
        display: block;
        position: absolute;
        height: 60px;
        width: 60px;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        z-index: 3;
    }
    svg circle, svg path{
        transition: all 250ms ease;
    }
    &:hover svg{
        circle{
            stroke: $brand-secondary;
        }
        path{
            fill: $brand-secondary;
        }
    }
}

[data-videoplayicon_position*="icon_top_center"]{
    top:70%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 3;
    @include media-breakpoint-only(md){
        top: 15px;
        transform: translateX(-50%);
    }
    @include media-breakpoint-up(lg){
        top:70%;
    }
}

.OverlayModal {
    background: rgba(0, 0, 0, .8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 16;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;
}

.video-close {
    background: $brand-secondary;
    border:40px;
    color: $brand-light;
    cursor: pointer;
    position: absolute;
    top: 70px;
    right: 15px;
    z-index: 2;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    font-size: 2em;
    line-height: 34px;
    text-align: center;
}

.videoSingle{
    cursor: pointer;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
}