@charset "UTF-8";
/*!
 * Bootstrap Grid v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
body.noScroll .cma-menu {
  opacity: 1;
  visibility: visible;
  transition: opacity 250ms ease, visibility 250ms ease;
}
body.noScroll .hamburger span:first-child {
  transform: translate(0px, 10px) rotate(45deg);
}
body.noScroll .hamburger span:nth-child(2) {
  opacity: 0;
}
body.noScroll .hamburger span:last-child {
  transform: rotate(-45deg) translate(7px, -7px);
}
body.lights header svg {
  width: 100%;
}
body.lights header svg path {
  fill: white;
}
body.lights header nav a:not(.current) {
  color: white;
}
body.dark svg {
  width: 100%;
}
body.dark svg path {
  fill: #2d2d2d;
}
body.dark nav a:not(.current) {
  color: #2d2d2d;
}

picture {
  display: block;
  line-height: 0;
}

.do-overflow {
  overflow: hidden;
  position: relative;
}

.fontTitles, .heading3, .heading2, .heading1 {
  font-family: "Poppins", sans-serif;
}

.container-hdr {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
  .container-hdr {
    padding-right: 50px;
    padding-left: 50px;
  }
}

a {
  text-decoration: none;
}

p {
  line-height: 1.5;
}

.dFlex {
  display: flex;
  flex-wrap: wrap;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.cmaBtn {
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 12px 30px;
  min-height: 41px;
  min-width: 230px;
  transition: color 250ms ease, border 250ms ease, background 250ms ease;
}
.cmaBtn-lined-secondary {
  background: #ffd500;
  color: #1c1c1c;
}
.cmaBtn-lined-secondary.cmaBtn-icon {
  align-items: center;
}
.cmaBtn-lined-secondary.cmaBtn-icon .cma-icon {
  width: 13px;
  height: 15px;
  margin-left: 10px;
}
.cmaBtn-lined-secondary.cmaBtn-icon .cma-icon svg path {
  fill: #1c1c1c;
}
.cmaBtn-lined-secondary:hover {
  background: #444444;
  color: white;
}
.cmaBtn-lined-secondary:hover .cma-icon svg path {
  fill: white;
}
.cmaBtn-secondary {
  background: #ffd500;
  color: #1c1c1c;
}
.cmaBtn-secondary:hover {
  background: #1c1c1c;
  color: #ffd500;
}

.singleLinkLight {
  color: white;
  transition: color 250ms ease;
}
.singleLinkLight:hover {
  color: #ffd500;
}

.smallSubHeading {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  display: block;
}
@media (min-width: 768px) {
  .smallSubHeading {
    font-size: 26px;
  }
}

.color-light {
  color: white !important;
}

.color-heading, .heading3, .heading2 {
  color: #2d2d2d;
}

.color-secondary {
  color: #ffd500;
}

@media (max-width: 991.98px) {
  .noPdxs {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767.98px) {
  .px-xs {
    padding-left: calc(8.33333333% + 15px);
    padding-right: calc(8.33333333% + 15px);
  }
}

.pdSections {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .pdSections {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.pdBlocks {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .pdBlocks {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media (max-width: 767.98px) {
  .mbNopad {
    padding: 0;
  }
}

.nMb {
  margin-bottom: 0 !important;
}

.text-center {
  text-align: center;
}

@media (max-width: 767.98px) {
  .text-center-xs {
    text-align: center;
  }
}

.text-hightlight {
  font-weight: 700;
}
@media (min-width: 768px) {
  .text-hightlight {
    font-size: 20px;
  }
}

.mB5 {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .mB5 {
    margin-bottom: 0;
  }
}

.mb5-1 {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .mb5-1 {
    margin-bottom: 100px;
  }
}

@media (max-width: 991.98px) {
  .mxMb {
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .row-md-reverse {
    flex-direction: row-reverse;
  }
}

h1, h2, h3, h4 {
  margin: 0;
}

.heading1 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.3px;
}
@media (min-width: 768px) {
  .heading1 {
    font-size: 45px;
    line-height: 1.2;
  }
}
@media (min-width: 1200px) {
  .heading1 {
    font-size: 66px;
  }
}

.heading2 {
  font-size: 27px;
  font-weight: 700;
  letter-spacing: -0.3px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .heading2 {
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .heading2 {
    font-size: 50px;
  }
}

.heading3 {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.3px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .heading3 {
    font-size: 25px;
    line-height: 1.33;
    margin-bottom: 55px;
  }
}
@media (min-width: 1200px) {
  .heading3 {
    font-size: 30px;
  }
}

.likeMask {
  overflow: hidden;
  position: relative;
}
.likeMask > * {
  display: block;
}

.fullImg,
.fullImg picture img {
  width: 100%;
}

.cma-banner {
  position: relative;
}
.cma-banner picture {
  position: relative;
  overflow: hidden;
}
.cma-banner img {
  width: 100%;
}
.cma-banner_caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cma-banner_caption .cma-banner_sub {
  color: #f3d331;
  font-size: 16px;
  font-weight: 300;
  display: block;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .cma-banner_caption .cma-banner_sub {
    font-size: 22px;
    margin-bottom: 65px;
  }
}

.cma-sec_header {
  position: relative;
}
.cma-sec_header picture, .cma-sec_header img {
  width: 100%;
}
.cma-sec_header .container {
  text-align: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

header {
  background: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  transition: background 250ms ease;
}
@media (min-width: 1200px) {
  header {
    padding-top: 30px;
  }
}
header .cma-logo {
  z-index: 4;
}
header .cma-menu {
  background: #2d2d2d;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 992px) {
  header .cma-menu {
    background: none;
    position: initial;
    flex-direction: row;
    justify-content: flex-end;
    height: auto;
    opacity: initial;
    visibility: visible;
    transition: initial;
  }
}
header .cma-menu > a {
  font-size: 20px;
  padding: 10px 20px;
  transition: color 250ms ease;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  header .cma-menu > a {
    font-size: 14px;
    margin-bottom: 0;
  }
}
header .cma-menu > a:hover, header .cma-menu > a.current {
  color: #ffd500;
}
header .cma-menu > a.lastLink {
  background: #ffd500;
  color: #2d2d2d !important;
  transition: background 250ms ease;
}
header .cma-menu > a.lastLink:hover {
  background: black;
  color: white !important;
}
header .socialNav {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 35px;
}
header .socialNav a {
  color: white;
  width: 40px;
  margin: 0 20px;
}
header .cma-mobile .hamburger span {
  background: white;
  border-radius: 2px;
  display: block;
  height: 4px;
  width: 100%;
  margin-bottom: 6px;
  transition: opacity 250ms ease, transform 250ms ease;
}
header.stick {
  background: rgba(22, 22, 22, 0.8);
  backdrop-filter: blur(3px);
}
header.stick .cma-logo svg path {
  fill: white !important;
}
header.stick nav a:not(.current, .lastLink) {
  color: white !important;
}

footer {
  background: #1c1c1c;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  footer {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 767.98px) {
  footer .cma-ftr-adrs {
    margin: 0 auto 50px auto;
  }
}
footer address {
  font-style: normal;
}
footer address span {
  display: block;
  font-weight: 700;
}
footer address a {
  color: white;
  transition: color 250ms ease;
}
footer address a:hover {
  color: #ffd500;
}
footer .cma-ftr_google {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
footer .cma-ftr_google img:first-child {
  width: 100px;
}
@media (min-width: 768px) {
  footer .cma-ftr_google {
    flex-wrap: wrap;
  }
}
@media (min-width: 1200px) {
  footer .cma-ftr_google {
    justify-content: flex-end;
  }
  footer .cma-ftr_google img:first-child {
    width: auto;
  }
  footer .cma-ftr_google img:last-child {
    margin-left: 30px;
  }
}
footer .cma-ftr_logo {
  margin-bottom: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  footer .cma-ftr_logo {
    margin-bottom: 0;
    text-align: initial;
  }
}
footer .cma-ftr_logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
footer .cma-ftr_logos a {
  line-height: 0;
  min-width: 95px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  footer .cma-ftr_logos a {
    min-width: 70px;
    margin-bottom: 0;
  }
}
footer .cma-ftr_social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  footer .cma-ftr_social {
    justify-content: initial;
    margin-bottom: 0;
  }
}
footer .cma-ftr_social .socialLink {
  color: white;
  width: 20px;
  margin-right: 20px;
  transition: color 250ms ease;
}
footer .cma-ftr_social .socialLink:hover {
  color: #ffd500;
}
footer .cma-ftr_copy {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 992px) {
  footer .cma-ftr_copy {
    justify-content: initial;
  }
}
footer .cma-ftr_copy span {
  margin-right: 25px;
}
@media (min-width: 992px) {
  footer .cma-ftr_copy span {
    margin-right: 50px;
  }
}
footer > .container > .row {
  margin-bottom: 50px;
}
footer > .container > .row:last-child {
  margin-bottom: 0;
}

.secMask .likeMask span, .secMask .likeMask .maskInner {
  transform: translateY(100%);
  opacity: 0;
  transition: transform 1s ease, opacity 1s ease;
}
.secMask .likeMask:nth-child(2) span {
  transition-delay: 250ms;
}
.secMask-in .likeMask span {
  opacity: 1;
  transform: translateY(0);
}

.setMask {
  overflow: hidden;
  position: relative;
}
.setMask .maskInnerFade {
  opacity: 0;
  transition: opacity 500ms ease;
}
.setMask .maskInner {
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 1s ease, opacity 1s ease;
}
.setMask-in .maskInnerFade {
  opacity: 1;
}
.setMask-in .maskInner {
  opacity: 1;
  transform: translateY(0);
}

.zoom {
  transform: scale(1.1);
  transition: transform 1s ease-out;
}
.zoomIn {
  transform: scale(1);
}

.fade {
  opacity: 0;
  transition: opacity 1s ease;
}
.fadeIn {
  opacity: 1;
}

.onlyDsk {
  opacity: 0;
  transition: transform 500ms ease, opacity 500ms ease;
}
@media (min-width: 992px) {
  .onlyDsk {
    transform: translateY(50px);
  }
  .onlyDsk:nth-child(2) {
    transition-delay: 250ms;
  }
  .onlyDsk:nth-child(3) {
    transition-delay: 500ms;
  }
  .onlyDsk:nth-child(4) {
    transition-delay: 750ms;
  }
}
.onlyDsk-in {
  opacity: 1;
}
@media (min-width: 992px) {
  .onlyDsk-in {
    transform: translateY(0);
  }
}

.smartSec .smartSec_elem {
  opacity: 0;
  transform: translateY(50px);
  transition: transform 300ms ease-out, opacity 300ms ease-out;
}
.smartSecIn .smartSec_elem {
  opacity: 1;
  transform: translateY(0);
}
.smartSecIn .smartSec_elem:nth-child(1) {
  transition-delay: 250ms;
}
.smartSecIn .smartSec_elem:nth-child(2) {
  transition-delay: 500ms;
}
.smartSecIn .smartSec_elem:nth-child(3) {
  transition-delay: 750ms;
}
.smartSecIn .smartSec_elem:nth-child(4) {
  transition-delay: 1000ms;
}
.smartSecIn .smartSec_elem:nth-child(5) {
  transition-delay: 1250ms;
}
.smartSecIn .smartSec_elem:nth-child(6) {
  transition-delay: 1500ms;
}
.smartSecIn .smartSec_elem:nth-child(7) {
  transition-delay: 1750ms;
}
.smartSecIn .smartSec_elem:nth-child(8) {
  transition-delay: 2000ms;
}
.smartSecIn .smartSec_elem:nth-child(9) {
  transition-delay: 2250ms;
}
.smartSecIn .smartSec_elem:nth-child(10) {
  transition-delay: 2500ms;
}
.smartSecIn .smartSec_elem:nth-child(11) {
  transition-delay: 2750ms;
}

.circleMask picture {
  mask-image: radial-gradient(#000 70%, transparent 70.1%);
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: 0vmax 0vmax;
  transition: mask-size 1s ease-in-out;
  transition-delay: 0.5s;
}
.circleMask.doMask picture {
  mask-size: 300vmax 300vmax;
  transition-delay: 0;
}

.bee-reel .VideoReel-box {
  position: relative;
  display: none;
}
.bee-reel .VideoReel-box.showme {
  height: auto;
}
.bee-reel .Video-data {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}
.bee-reel iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.bee-reel .videoLink {
  margin: 10px auto 0;
  position: absolute;
  left: 0;
  right: 0;
  width: 180px;
}
.bee-reel .reel-close {
  background: #ffd500;
  border: 40px;
  color: white;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 15px;
  z-index: 2;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  font-size: 2em;
  line-height: 34px;
  text-align: center;
}
@media (min-width: 992px) {
  .bee-reel .reel-close {
    bottom: 100px;
  }
}

.Video-box {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  max-width: 1000px;
  z-index: 17;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}
@media (min-width: 1600px) {
  .Video-box {
    max-width: 1400px;
  }
}
@media (max-height: 900px) {
  .Video-box {
    top: 70px;
    transform: translate(-50%, 0);
  }
}
.Video-box.showme, .Video-box.showme ~ .OverlayModal, .Video-box.showme ~ .video-disclaimer-box {
  opacity: 1;
  visibility: visible;
}
.Video-box .Video-data {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}
.Video-box iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.Video-box .videoLink {
  margin: 10px auto 0;
  position: absolute;
  left: 0;
  right: 0;
  width: 180px;
}

.video_local {
  display: block;
  width: 95%;
  margin: 0 auto;
}
.video_local:focus {
  outline: none;
}

[data-videoplayicon] {
  cursor: pointer;
}
[data-videoplayicon] .icon-play {
  display: block;
  position: absolute;
  height: 60px;
  width: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
[data-videoplayicon] svg circle, [data-videoplayicon] svg path {
  transition: all 250ms ease;
}
[data-videoplayicon]:hover svg circle {
  stroke: #ffd500;
}
[data-videoplayicon]:hover svg path {
  fill: #ffd500;
}

[data-videoplayicon_position*=icon_top_center] {
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  [data-videoplayicon_position*=icon_top_center] {
    top: 15px;
    transform: translateX(-50%);
  }
}
@media (min-width: 992px) {
  [data-videoplayicon_position*=icon_top_center] {
    top: 70%;
  }
}

.OverlayModal {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 16;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

.video-close {
  background: #ffd500;
  border: 40px;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 70px;
  right: 15px;
  z-index: 2;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  font-size: 2em;
  line-height: 34px;
  text-align: center;
}

.videoSingle {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
}

.formBox_subjects {
  margin-bottom: 50px;
}
.formBox .input_g {
  position: relative;
  margin-bottom: 30px;
}
.formBox label {
  color: #2d2d2d;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .formBox label {
    font-size: 16px;
  }
}
.formBox input, .formBox textarea, .formBox select {
  background: transparent;
  color: #201f1f;
  border: none;
  border-bottom: 1px solid #201f1f;
  font-family: "Open Sans", sans-serif;
  height: 40px;
  width: 100%;
  padding: 0 10px;
}
.formBox input:focus, .formBox input.danger, .formBox textarea:focus, .formBox textarea.danger, .formBox select:focus, .formBox select.danger {
  outline: none;
  border-bottom-color: #ee6262;
}
.formBox textarea {
  overflow: auto;
  height: 100px;
}
.formBox button {
  cursor: pointer;
}
.formBox .customSelect {
  appearance: none;
  background: url(/images/arrow-down.svg) no-repeat 95% center;
  background-size: 10px;
}
.formBox .fileUpload {
  display: flex;
  align-items: center;
}
.formBox .fileUpload .lblBtn {
  background: #2d2d2d;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
}
.formBox input[type=file] {
  appearance: none;
  border: none;
}
.formBox .text-danger {
  color: #eb6d6d;
  font-size: 14px;
  display: block;
  padding: 5px 0;
}
.formBox .pdfName {
  padding-left: 10px;
}

.is_home .cma-banner_caption {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.is_home .cma-banner_caption em {
  font-style: normal;
}
.is_home .cma-banner_caption h1 {
  margin-right: auto;
  font-size: 12px;
  margin-top: 55px;
}
@media (min-width: 768px) {
  .is_home .cma-banner_caption h1 {
    margin-top: 100px;
    margin-left: 5.3%;
  }
}
.is_home .cma-banner .cma-actions {
  margin-top: auto;
  margin-bottom: 50px;
}
@media (min-width: 1200px) {
  .is_home .cma-banner .cma-actions {
    margin-bottom: 100px;
  }
}
.is_home .cma-banner .cma-rotaing {
  color: white;
  font-size: 18px;
  text-align: center;
  min-height: 100px;
}
@media (min-width: 992px) {
  .is_home .cma-banner .cma-rotaing {
    padding-left: 25%;
    padding-right: 25%;
    font-size: 20px;
  }
}
.is_home .cma-banner_sub {
  font-size: 26px;
  font-style: italic;
}
@media (min-width: 768px) {
  .is_home .cma-banner_sub {
    font-size: 36px;
  }
}
@media (max-width: 991.98px) {
  .is_home .cma-actions a {
    margin-bottom: 30px;
  }
}
.is_home .cma-expand .heading2 {
  margin-bottom: 30px;
}
.is_home .cma-expand .heading2 .subHeading2 {
  display: block;
  font-size: 26px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .is_home .cma-expand .heading2 .subHeading2 {
    font-size: 26px;
  }
}
.is_home .cma-expand_customers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}
.is_home .cma-expand_customers img {
  margin: 0 17px 35px;
}
@media (min-width: 768px) {
  .is_home .cma-expand_customers img {
    margin: 0 30px 35px;
  }
}
.is_home .cma-insight {
  font-size: 20px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .is_home .cma-insight {
    margin-bottom: 90px;
  }
}
.is_home .cma-featured {
  background: #201f1f;
}
@media (min-width: 768px) {
  .is_home .cma-featured_title {
    padding-bottom: 100px;
  }
}
.is_home .cma-featured_title .heading2 {
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .is_home .cma-featured_wrap img {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .is_home .cma-services .imgNoFluid {
    max-width: 100%;
    height: auto;
  }
}
@media (min-width: 992px) {
  .is_home .cma-services .imgNoFluid {
    margin-left: -128px;
  }
}
.is_home .cma-services .cma-icon {
  width: 12px;
  display: block;
  transition: transform 250ms ease;
  margin-top: auto;
}
.is_home .cma-services .cma-icon svg path {
  transition: fill 250ms ease;
}
@media (min-width: 992px) {
  .is_home .cma-services_links {
    padding-top: 8%;
  }
}
.is_home .cma-services_link {
  color: #2d2d2d;
  padding: 30px;
  transition: box-shadow 250ms ease;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.is_home .cma-services_link h2 {
  color: #2d2d2d;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .is_home .cma-services_link h2 {
    font-size: 20px;
  }
}
.is_home .cma-services_link p {
  line-height: 1.5;
}
.is_home .cma-services_link p strong {
  color: #eabb00;
  display: block;
}
.is_home .cma-services_link:hover {
  box-shadow: 0px 11px 35px 0 rgba(0, 0, 0, 0.15);
}
.is_home .cma-services_link:hover .cma-icon {
  transform: translateX(10px);
}
.is_home .cma-services_link:hover .cma-icon svg path {
  fill: #ffd500;
}

.cma-power {
  padding-top: 90px;
  padding-bottom: 0;
  position: relative;
}
.cma-power::after {
  background-color: #f5f2e7;
  content: "";
  height: 55%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.cma-power .cma-secTitle {
  padding-bottom: 0;
}
.cma-power .cma-secTitle .heading2 {
  margin-bottom: 0;
}
.cma-power .cma-somos {
  font-size: 18px;
  margin-bottom: 120px;
}
.cma-power .cma-comunidad_somos {
  font-size: 18px;
  margin-bottom: 70px;
}
.cma-power_items {
  margin-bottom: 70px;
}
.cma-power_item {
  font-size: 18px;
  padding: 70px 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.cma-power_item:nth-child(2) {
  background: #ffd500;
}
@media (min-width: 768px) {
  .cma-power_item:nth-child(2) {
    top: -50px;
  }
}
.cma-power_item:nth-child(1), .cma-power_item:nth-child(3) {
  background: #2d2d2d;
  color: white;
}
.cma-power_item p {
  margin: 0;
}
.cma-power_item .cma-icon {
  display: inline-block;
  width: 50px;
}

.cma-comunidad span {
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.cma-somos_ex .heading2 {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.cma-somos_big {
  color: rgba(236, 231, 215, 0.6);
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  font-size: 126px;
}
@media (min-width: 1200px) {
  .cma-somos_big {
    font-size: 120px;
  }
}

.cma-agency {
  background: url("/images/branding-marketing-colmena.webp") #201f1f no-repeat center right;
}
@media (max-width: 991.98px) {
  .cma-agency .cma-services_left img {
    margin-bottom: 50px;
  }
}

.cma-agency_grid {
  display: inline-grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  grid-auto-rows: 1fr;
  grid-auto-flow: dense;
  align-self: center;
}
.cma-agency_grid .gridItem {
  border: 1px solid #ffd500;
  color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 33px 10px;
}
.cma-agency_grid .gridItem:nth-child(1) {
  border: none;
}
.cma-agency_grid .gridItem:nth-child(1) figure {
  margin: 0;
}
.cma-agency_grid .gridItem:nth-child(2) {
  margin-left: -1px;
}
.cma-agency_grid .gridItem:nth-child(3) {
  border-bottom: none;
}
.cma-agency_grid .gridItem:nth-child(4) {
  grid-row: span 2;
  border-left: none;
  border-top: none;
  margin-left: -1px;
}
.cma-agency_grid .gridItem:nth-child(4) figure {
  margin: 5px 0;
  min-width: 70px;
}
.cma-agency_grid .gridItem:nth-child(4) figure:first-child {
  margin-top: 20px;
}
.cma-agency_grid .gridItem span {
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .cma-agency_grid .gridItem span {
    font-size: 33px;
  }
}

.is_seo .seo-important {
  padding-top: 50px;
}
@media (min-width: 768px) {
  .is_seo .seo-important {
    overflow-x: hidden;
    padding-top: 70px;
  }
}
.is_seo .seo-important_seo {
  padding: 60px 73px 65px 62px;
  box-shadow: 0 0 32px 0 rgba(105, 105, 105, 0.55);
  background-color: #fff;
}
@media (min-width: 768px) {
  .is_seo .seo-important_seo {
    margin-right: -8.3333333%;
    margin-left: 8.3333333%;
    z-index: 2;
  }
}
.is_seo .seo-important_seo-img {
  padding: 0;
}
.is_seo .seo-pillars {
  background: url("/images/3-pilares-seo-mobile.webp") no-repeat left 160px;
}
@media (min-width: 768px) {
  .is_seo .seo-pillars {
    background: url("/images/3-pilares-seo.webp") no-repeat center center;
    padding-bottom: 30px;
  }
}
.is_seo .seo-pillars_3 {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  margin-bottom: 115px;
}
@media (min-width: 768px) {
  .is_seo .seo-pillars_3 {
    justify-content: initial;
    line-height: normal;
    margin-bottom: 0;
  }
}
.is_seo .seo-pillars_3 span:first-child {
  font-size: 120px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .is_seo .seo-pillars_3 span:first-child {
    font-size: 153px;
  }
}
.is_seo .seo-pillars_3 span:last-child {
  font-size: 38px;
  font-weight: bold;
  line-height: 1.24;
}
@media (min-width: 768px) {
  .is_seo .seo-pillars_3 span:last-child {
    font-size: 50px;
  }
}
.is_seo .seo-pillars_3 span:last-child em {
  color: #ffd500;
  font-style: normal;
}
@media (min-width: 768px) {
  .is_seo .seo-pillars_data {
    padding-top: 70px;
  }
}
.is_seo .seo-pillars_data-text span {
  font-size: 28px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  position: relative;
}
@media (min-width: 768px) {
  .is_seo .seo-pillars_data-text span {
    font-size: 24px;
  }
}
.is_seo .seo-pillars_data-text span::after {
  content: url("/images/flecha-larga.svg");
  display: block;
  width: 70px;
  height: 2;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-63%);
  margin-right: 10px;
}
.is_seo .seo-pillars_data-text p {
  font-size: 14px;
  line-height: 1.71;
  margin: 0 0 50px 0;
}
@media (min-width: 768px) {
  .is_seo .seo-web {
    margin-bottom: 75px;
  }
}
@media (min-width: 768px) {
  .is_seo .seo-web_data {
    padding-right: 50px;
  }
}
.is_seo .seo-web_data > ul {
  color: #ffd500;
  padding-left: 20px;
}
.is_seo .seo-web_data > ul p {
  color: #1c1c1c;
  line-height: 24px;
}
.is_seo .seo-web_data > ul p strong {
  font-size: 18px;
}
.is_seo .seo-web_data > ul li > ul {
  list-style-image: url("/images/bullet-arrow.svg");
  color: #1c1c1c;
}
@media (max-width: 991.98px) {
  .is_seo .seo-web_img {
    padding: 50px 0 0 0;
    line-height: 0;
  }
}
.is_seo .seo-help {
  background: #f5f1e7;
  padding-top: 50px;
  overflow-x: clip;
}
@media (min-width: 768px) {
  .is_seo .seo-help {
    padding-top: 100px;
  }
}
.is_seo .seo-help_data {
  counter-reset: lis;
}
.is_seo .seo-help_data ul {
  margin-top: 0;
}
@media (min-width: 1550px) {
  .is_seo .seo-help_data ul {
    padding-right: 40px;
  }
}
.is_seo .seo-help_data li {
  display: flex;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 24px;
}
@media (min-width: 1550px) {
  .is_seo .seo-help_data li {
    margin-bottom: 35px;
  }
}
.is_seo .seo-help_data li:before {
  background: #ffd500;
  border-radius: 50%;
  counter-increment: lis;
  content: counter(lis);
  height: 27px;
  width: 27px;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
.is_seo .seo-help_data li p {
  flex: 1;
  margin: 0;
}
.is_seo .seo-help_data li strong {
  font-size: 18px;
  font-weight: 600;
}
.is_seo .seo-help_img {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .is_seo .seo-help_img {
    margin-bottom: 0;
  }
}
.is_seo .seo-help_img img {
  max-width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .is_seo .seo-help_img img {
    position: absolute;
    left: -50px;
    width: 40vw;
    max-width: initial;
  }
}
.is_seo .seo-intervention {
  background: #222222;
  padding-top: 8%;
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  .is_seo .seo-intervention {
    background: url("/images/expandir-el-poder-de-tu-sitio.webp") no-repeat center center/cover;
    padding-bottom: 100px;
  }
}
@media (min-width: 1550px) {
  .is_seo .seo-intervention {
    padding-top: 14%;
  }
}
.is_seo .seo-intervention h2 {
  color: white;
  text-align: center;
}
.is_seo .seo-intervention .seo-sldr {
  margin-bottom: 70px;
}
@media (min-width: 992px) {
  .is_seo .seo-intervention .seo-sldr {
    display: flex;
    flex-wrap: wrap;
  }
}
.is_seo .seo-intervention .seo-sldr .slick-slide:nth-child(2) .item {
  background-color: #ffd500;
  box-shadow: 0 0 147px 0 rgba(46, 46, 46, 0.45);
  position: relative;
  padding: 60px 45px;
  z-index: 1;
  margin-top: -20px;
  margin-bottom: 20px;
}
.is_seo .seo-intervention .seo-sldr .item {
  background-color: #f3efe3;
  display: flex;
  align-items: center;
  padding: 40px 30px;
  width: 33.3333333%;
  line-height: 1.63;
  height: 290px;
}
@media (min-width: 992px) {
  .is_seo .seo-intervention .seo-sldr .item {
    height: auto;
  }
}
.is_seo .seo-intervention .seo-sldr .item:nth-child(2) {
  background-color: #ffd500;
  box-shadow: 0 0 147px 0 rgba(46, 46, 46, 0.45);
  position: relative;
  padding: 60px 45px;
  z-index: 1;
  margin-top: -20px;
  margin-bottom: 20px;
}
.is_seo .seo-intervention .seo-sldr .item p {
  margin: 0;
}
.is_seo .seo-intervention .seo-sldr .slick-dots {
  margin-bottom: -20px;
}
.is_seo .seo-intervention .seo-sldr .slick-dots li {
  border: 1px solid white;
  border-radius: 50%;
  height: 15px;
  width: 15px;
}
.is_seo .seo-intervention .seo-sldr .slick-dots li.slick-active {
  background: white;
}
.is_seo .seo-intervention .seo-sldr .slick-dots li button {
  height: inherit;
  width: inherit;
}
.is_seo .seo-intervention .seo-sldr .slick-dots li button:before {
  content: initial;
}
.is_seo .seo-intervention_ftr {
  text-align: center;
}
.is_seo .seo-intervention_ftr p {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}
.is_seo .seo-consultancy {
  background: #f5f1e7;
  padding-top: 50px;
}
@media (min-width: 992px) {
  .is_seo .seo-consultancy {
    overflow-x: clip;
    padding-top: 0;
  }
}
.is_seo .seo-consultancy_data {
  line-height: 1.5;
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .is_seo .seo-consultancy_data {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    min-height: 625px;
    margin-bottom: 0;
  }
}
@media (min-width: 1550px) {
  .is_seo .seo-consultancy_data {
    min-height: 920px;
  }
}
.is_seo .seo-consultancy_data .seo-hightlight {
  font-size: 20px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .is_seo .seo-consultancy_data .seo-hightlight {
    font-size: 20px;
  }
}
.is_seo .seo-consultancy_data p {
  margin: 0 0 20px 0;
}
.is_seo .seo-consultancy_img {
  padding: 0;
  line-height: 0;
}
.is_seo .seo-consultancy_img img {
  max-width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .is_seo .seo-consultancy_img img {
    position: absolute;
    left: 0;
    width: 40.5vw;
    max-width: initial;
  }
}
@media (min-width: 992px) {
  .is_seo .seo-consultancy_img.imgLeft img {
    left: initial;
    right: 0;
  }
}
.is_seo .seo-onSite {
  background: #201f1f;
  color: white;
}
@media (min-width: 992px) {
  .is_seo .seo-onSite {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}
.is_seo .seo-onSite strong.seo-hightlight {
  color: #ffd500;
  font-size: 16px;
}
.is_seo .seo-onSite .heading2 {
  color: white;
}
.is_seo .seo-onSite .seo-consultancy_data {
  padding-top: 50px;
}
.is_seo .seo-onSite ul {
  color: #ffd500;
}
.is_seo .seo-onSite ul p {
  color: white;
}
.is_seo .seo-offSite {
  padding-top: 50px;
}
@media (min-width: 768px) {
  .is_seo .seo-offSite {
    padding-top: 75px;
    padding-bottom: 75px;
    overflow-x: clip;
  }
}
.is_seo .seo-offSite_data {
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .is_seo .seo-offSite_data {
    min-height: 650px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  }
}
@media (min-width: 1550px) {
  .is_seo .seo-offSite_data {
    min-height: 900px;
  }
}
.is_seo .seo-offSite_data .seo-hightlight {
  font-size: 20px;
  margin-bottom: 20px;
}
.is_seo .seo-offSite_data ul li {
  color: #ffd500;
}
.is_seo .seo-offSite_data ul li span {
  color: #1c1c1c;
}
.is_seo .seo-offSite_tactics {
  margin-bottom: 30px;
}
.is_seo .seo-offSite_img {
  padding: 0;
  line-height: 0;
}
.is_seo .seo-offSite_img img {
  width: 100%;
}
@media (min-width: 992px) {
  .is_seo .seo-offSite_img img {
    width: 40vw;
  }
}
.is_seo .seo-serviceType {
  background: #1c1c1c;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  .is_seo .seo-serviceType {
    background: url("/images/tipo-de-servicios.webp") no-repeat center center/cover;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 767.98px) {
  .is_seo .seo-serviceType .heading2 {
    text-align: center;
  }
}
.is_seo .seo-serviceType_grid .row {
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .is_seo .seo-serviceType_grid .row {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.is_seo .seo-serviceType_item:not(:empty) {
  border: 1px solid #ffd500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 115px;
  margin: -1px 0 0 -1px;
}
@media (min-width: 768px) {
  .is_seo .seo-serviceType_item:not(:empty) {
    font-size: 20px;
    height: 162px;
  }
  .is_seo .seo-serviceType_item:not(:empty):nth-child(3) {
    margin-right: 25%;
  }
  .is_seo .seo-serviceType_item:not(:empty):nth-child(4) {
    margin-left: calc(25% - 2px);
  }
}
.is_seo .seo-complementary {
  background: #f5f1e7;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .is_seo .seo-complementary {
    padding-top: 80px;
  }
}
.is_seo .seo-complementary_data {
  display: flex;
  align-items: center;
  padding-top: 50px;
}
@media (min-width: 768px) {
  .is_seo .seo-complementary_data {
    padding-left: 50px;
    padding-top: 0;
  }
}
.is_seo .seo-complementary_img {
  padding: 0;
  line-height: 0;
}
@media (min-width: 768px) {
  .is_seo .seo-complementary_img {
    padding: 0 15px;
  }
}
.is_seo .seo-strategy {
  font-family: "Poppins", sans-serif;
}
.is_seo .seo-strategy span {
  font-size: 20px;
  font-weight: 500;
  display: block;
}
@media (min-width: 768px) {
  .is_seo .seo-strategy span {
    font-size: 22px;
  }
}
.is_seo .seo-strategy p {
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 30px 0;
}
@media (min-width: 768px) {
  .is_seo .seo-strategy p {
    font-size: 36px;
  }
}

.is_services .cma-sec_header .subTitle {
  color: #ffd500;
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .is_services .cma-sec_header .subTitle {
    font-size: 26px;
  }
}
.is_services .cma-sec_header p {
  margin: 0;
}
.is_services .cma-sec_header .subTitleTxt {
  color: white;
  font-size: 18px;
  line-height: 1.56;
}
@media (min-width: 768px) {
  .is_services .cma-sec_header .subTitleTxt {
    font-size: 18px;
  }
}
.is_services .cma-services_stack {
  background: white;
  overflow: hidden;
  min-height: 76vh;
}
.is_services .cma-services_stack.strategy {
  padding-top: 50px;
}
.is_services .cma-services_stack h2 {
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .is_services .cma-services_stack p {
    padding-right: 8.33333333%;
  }
}
.is_services .cma-services_stack figure {
  margin: 0;
  padding: 0;
  position: relative;
}
@media (min-width: 992px) {
  .is_services .cma-services_stack {
    min-height: 106vh;
  }
  .is_services .cma-services_stack:nth-child(even) .cma-services_stack-list {
    right: 0;
    left: initial;
  }
}
.is_services .cma-services_stack.bc {
  background: #f4f1e7;
  padding-top: 50px;
}
@media (min-width: 768px) {
  .is_services .cma-services_stack.bc {
    padding-top: 0;
  }
}
@media (max-width: 767.98px) {
  .is_services .cma-services_stack-data {
    padding-left: 8.3333333333%;
    padding-right: 8.3333333333%;
  }
  .is_services .cma-services_stack-data p {
    margin-bottom: 50px;
  }
}
.is_services .cma-services_stack-img {
  padding: 50px 0;
}
.is_services .cma-services_stack-img::after {
  content: "";
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}
@media (min-width: 768px) {
  .is_services .cma-services_stack-img::after {
    height: 100%;
  }
}
.is_services .cma-services_stack-img img {
  position: relative;
  z-index: 2;
}
@media (max-width: 767.98px) {
  .is_services .cma-services_stack-img img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.is_services .cma-services_stack-img .leftImg {
  margin-left: 0;
}
@media (min-width: 992px) {
  .is_services .cma-services_stack-img .leftImg {
    margin-left: -46%;
  }
}
@media (min-width: 1200px) {
  .is_services .cma-services_stack-img .leftImg {
    margin-left: -23%;
  }
}
.is_services .cma-services_stack-img.sqbeige::after {
  background: #f4f1e7;
  left: 37%;
}
.is_services .cma-services_stack-img.sqblack::after {
  background: #2d2d2d;
  left: -37%;
}
.is_services .cma-services_stack-img.sqTalk::after {
  background: #2d2d2d;
  left: 37%;
}
.is_services .cma-services_stack-img.sqSite::after {
  background: #ffd500;
  left: -37%;
}
.is_services .cma-services_stack-list {
  background: white;
  box-shadow: 41px 0px 57px 0 rgba(0, 0, 0, 0.2);
  padding: 45px 8.333333%;
  position: relative;
  left: 0;
  z-index: 2;
}
@media (min-width: 768px) {
  .is_services .cma-services_stack-list {
    padding: 45px 70px;
    position: absolute;
    top: 65%;
  }
}
.is_services .cma-services_stack-list h3 {
  color: #d2b429;
  font-size: 20px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .is_services .cma-services_stack-list h3 {
    font-size: 20px;
  }
}
.is_services .cma-services_stack-list ul {
  color: #d2b429;
  line-height: 1.5;
}
.is_services .cma-services_stack-list ul span {
  color: #343434;
}
@media (max-width: 767.98px) {
  .is_services .cma-services_stack:nth-child(even) > .container > .row {
    flex-direction: column-reverse;
  }
}
.is_services .cma-agencySeo {
  color: white;
  padding-top: 50px;
}
@media (max-width: 767.98px) {
  .is_services .cma-agencySeo {
    background: url("/images/agencia-digital-especializada-en-seo-movil.webp") no-repeat center center;
    background-size: cover;
  }
}
@media (min-width: 768px) {
  .is_services .cma-agencySeo {
    background: url("/images/agencia-digital-especializada-en-seo.webp") no-repeat center center;
    background-size: cover;
    padding-top: 100px;
  }
}
.is_services .cma-agencySeo h2 {
  margin-bottom: 10px;
}
.is_services .cma-agencySeo-txt1 {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .is_services .cma-agencySeo-txt1 {
    font-size: 18px;
  }
}
.is_services .cma-agencySeo-txt2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .is_services .cma-agencySeo-txt2 {
    font-size: 20px;
  }
}
.is_services .cma-agencySeo .cma-cta {
  padding-bottom: 70px;
}
@media (min-width: 768px) {
  .is_services .cma-agencySeo .cma-cta {
    padding-bottom: 100px;
  }
}
.is_services .cma-collaborate {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 767.98px) {
  .is_services .cma-collaborate .heading2 {
    padding-left: 8.3333333%;
    padding-right: 8.3333333%;
  }
}
@media (min-width: 768px) {
  .is_services .cma-collaborate {
    padding-top: 100px;
    padding-bottom: 150px;
  }
}
.is_services .cma-collaborate .cma-collaborate_item {
  background: white;
  padding: 40px 60px;
}
.is_services .cma-collaborate .cma-collaborate_item p {
  font-size: 18px;
  line-height: 1.33;
}
@media (min-width: 768px) {
  .is_services .cma-collaborate .cma-collaborate_item p {
    font-size: 16px;
  }
}
.is_services .cma-collaborate .cma-collaborate_item .cma-icon {
  display: inline-block;
  width: 80px;
}
@media (min-width: 992px) {
  .is_services .cma-collaborate .cma-collaborate_item:nth-child(odd) {
    top: 50px;
  }
}
@media (min-width: 992px) {
  .is_services .cma-collaborate .cma-collaborate_item:nth-child(even) {
    box-shadow: -4px -0.3px 21px 0 rgba(0, 0, 0, 0.18);
    z-index: 2;
  }
}
.is_services .cma-collaborate .cma-collaborate_item:nth-child(1) {
  background: #f4f1e7;
}
.is_services .cma-collaborate .cma-collaborate_item:nth-child(2) {
  background: #f4d42f;
}
.is_services .cma-collaborate .cma-collaborate_item:nth-child(2) .cma-icon {
  width: 37px;
}
.is_services .cma-collaborate .cma-collaborate_item:nth-child(3) {
  background: #3e3e3e;
  color: white;
}
.is_services .cma-digital {
  background: url("/images/branding-marketing-colmena.webp") #252424 no-repeat right center;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  .is_services .cma-digital {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 767.98px) {
  .is_services .cma-digitalTitle {
    padding-left: 8.333333333%;
    padding-right: 8.33333333%;
  }
}
@media (max-width: 767.98px) {
  .is_services .cma-digitalMap {
    margin-bottom: 50px;
  }
}
.is_services .cma-digital .heading2 {
  margin-bottom: 0;
}
.is_services .cma-digital p {
  margin-bottom: 50px;
}
.is_services .cma-digital .cma-agency_grid .gridItem {
  padding-left: 25px;
  padding-right: 25px;
}
.is_services .cma-digital .cma-agency_grid .gridItem figure {
  margin: 0;
  min-width: 80px;
}
.is_services .cma-digital .cma-agency_grid .gridItem p {
  margin-bottom: 0;
}
.is_services .cma-digital .cma-agency_grid .gridItem:nth-child(4) {
  grid-row: initial;
}
.is_services .cma-customersTitle {
  margin-bottom: 50px;
}
.is_services .cma-customers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.is_services .cma-customers img {
  margin: 0 17px 35px;
}
@media (min-width: 768px) {
  .is_services .cma-customers img {
    margin: 0 30px 35px;
  }
}
@media (min-width: 992px) {
  .is_services .cma-customers img:nth-child(1), .is_services .cma-customers img:nth-child(12) {
    margin-left: 6vw;
  }
}
@media (min-width: 992px) {
  .is_services .cma-customers img:nth-child(5), .is_services .cma-customers img:nth-child(16) {
    margin-right: 6vw;
  }
}

.is_projectList header nav a {
  color: #2d2d2d;
}
.is_projectList header .cma-logo svg path {
  fill: #201f1f;
}
.is_projectList .cma-featured {
  padding-top: 15vh;
  background: #f4f1e7;
}
.is_projectList .cma-featured_title {
  padding-bottom: 40px;
}
.is_projectList .cma-actions_txt {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .is_projectList .cma-actions_txt {
    margin-bottom: 0;
  }
}
.is_projectList .cma-actions_txt span {
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .is_projectList .cma-actions_txt span {
    font-size: 30px;
  }
}

.cma-featured_item {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .cma-featured_item:nth-child(3n+2) .cma-featured_wrap {
    margin-top: -30px;
  }
}
.cma-featured_item figure {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  backface-visibility: hidden;
  mask-image: -webkit-radial-gradient(white, black);
  transform: translateY(100%);
  transition: transform 1s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.cma-featured_item figure img {
  position: relative;
  transform: translateY(-100%) scale(1.1);
  transition: transform 1s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.cma-featured_itemCaption {
  color: white;
  padding: 0;
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 20px;
}
@media (min-width: 768px) {
  .cma-featured_itemCaption {
    padding: 0 30px;
  }
}
.cma-featured_itemCaption h3 {
  font-size: 14px;
  font-weight: normal;
}
@media (min-width: 768px) {
  .cma-featured_itemCaption h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }
}
.cma-featured_itemCaption p {
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}
@media (min-width: 768px) {
  .cma-featured_itemCaption p {
    font-size: 16px;
  }
}
.cma-featured_item.EntryItem figure {
  transform: translateY(0);
}
.cma-featured_item.EntryItem figure img {
  transform: translateY(0) scale(1);
}
.cma-featured_item .cma-featured_wrap {
  display: block;
  position: relative;
}
.cma-featured_item .cma-featured_wrap:hover figure img {
  transform: scale(1.05);
}

/**Detail**/
.is_project .cma-project_header {
  position: relative;
}
.is_project .cma-project_header picture, .is_project .cma-project_header img {
  width: 100%;
}
.is_project .cma-project_header .container {
  text-align: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.is_project .cma-project_header .container h1 span {
  font-size: 26px;
  font-weight: 300;
  display: block;
  text-transform: uppercase;
}
.is_project .cma-project_header .container h1 p {
  margin: 0;
}

@media (max-width: 991.98px) {
  .cma-reto_logo {
    margin: 0 auto 50px auto;
  }
}

.cma-detailTitle {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .cma-detailTitle {
    font-size: 40px;
  }
}

.cma-video {
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #f4f1e7 50%);
}
.cma-video .container {
  position: relative;
}
.cma-video .container picture, .cma-video .container img {
  width: 100%;
}

.strechImg,
.strechImg img {
  width: 100%;
}

.videoSingle {
  cursor: pointer;
}
.videoSingle svg {
  transform-origin: center;
  transition: transform 250ms ease;
}
.videoSingle svg path {
  fill: white;
}
.videoSingle:hover svg {
  transform: scale(1.1);
}

.cma-weDid {
  background: #f4f1e7;
}

.cma-achieve {
  padding-bottom: 100px;
}

.cma-projectResult {
  padding-top: 70px;
  padding-bottom: 70px;
}
@media (min-width: 768px) {
  .cma-projectResult {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 768px) {
  .cma-projectResult_title {
    padding-bottom: 100px;
  }
}
.cma-projectResult .cma-result_item {
  padding: 70px;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .cma-projectResult .cma-result_item {
    padding: 27px;
  }
}
.cma-projectResult .cma-result_item:first-child {
  background: #f4f1e7;
}
.cma-projectResult .cma-result_item:nth-child(2) {
  background: #f4d42f;
}
@media (min-width: 768px) {
  .cma-projectResult .cma-result_item:nth-child(2) {
    margin-top: -30px;
    margin-bottom: 30px;
  }
}
.cma-projectResult .cma-result_item:nth-child(3) {
  background: #3e3e3e;
  color: white;
}
.cma-projectResult .cma-result_item span {
  font-size: 50px;
  font-weight: bold;
  display: block;
}
@media (min-width: 768px) {
  .cma-projectResult .cma-result_item span {
    font-size: 50px;
  }
}
.cma-projectResult .cma-result_item p {
  font-size: 22px;
  line-height: 1.18;
  margin: 0;
}
@media (min-width: 768px) {
  .cma-projectResult .cma-result_item p {
    font-size: 22px;
  }
}

.cma-projectServices {
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .cma-projectServices {
    padding-bottom: 100px;
  }
}
.cma-projectServices-title {
  line-height: 1.1;
}
.cma-projectServices_items {
  padding-left: 50px;
  color: #ffd500;
}
.cma-projectServices_items li {
  margin-bottom: 10px;
}
.cma-projectServices_items li span {
  color: #2d2d2d;
}

.cma-paginator {
  padding-bottom: 70px;
}
@media (min-width: 768px) {
  .cma-paginator {
    padding-bottom: 100px;
  }
}
.cma-paginator_inner {
  display: flex;
  justify-content: space-between;
}
.cma-paginator_inner .cmaBtn {
  min-width: 170px;
}

.is_contact .cma-sec_header .subTitle {
  color: #ffd500;
  font-weight: 300;
  margin-bottom: 20px;
}
.is_contact .heading2 {
  margin: 0 0 40px 0;
}
.is_contact_tks .cma-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.is_contact_tks .cma-contact_txt {
  text-align: center;
}
.is_contact_tks .cma-contact_txt h2 {
  margin-bottom: 0;
}
.is_contact_tks .cma-contact_txt p {
  font-size: 18px;
}

.cma-contact {
  background: #f4f1e7;
}
@media (min-width: 768px) {
  .cma-contact {
    background: url("/images/contacta-colmena-interactive-digital.webp") #f5f2e7 no-repeat left bottom;
  }
}

.cma-contact_title {
  margin-bottom: 70px;
}

.formBox_subjects .jdTab {
  padding: 0;
  list-style-type: none;
}
.formBox_subjects .jdTab li {
  margin-bottom: 5px;
  transition: background 250ms ease;
}
.formBox_subjects .jdTab li a {
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 600;
  display: block;
  text-decoration: underline;
  padding: 10px;
  transition: color 250ms ease;
}
.formBox_subjects .jdTab li.current, .formBox_subjects .jdTab li:hover {
  background: #201f1f;
}
.formBox_subjects .jdTab li.current a, .formBox_subjects .jdTab li:hover a {
  color: white;
  text-decoration: none;
}