header{
    background:transparent;
    padding-top: 15px;
    padding-bottom: 15px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    transition: background 250ms ease;
    @include media-breakpoint-up(xl){
        padding-top: 30px;
    }
    .cma-logo{
       z-index: 4;
    }
    .cma-menu{
        background: $brand-title;
        display:flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        justify-content: center;
        position: fixed;
        top: 0;
        opacity: 0;
        visibility: hidden;
        
        @include media-breakpoint-up(lg){
            background: none;
            position: initial;
            flex-direction: row;
            justify-content: flex-end;
            height: auto;
            opacity: initial;
            visibility: visible;
            transition: initial;
        }
        >a{
            font-size: 20px;
            padding: 10px 20px;
            transition: color 250ms ease;
            margin-bottom: 20px;
            @include media-breakpoint-up(lg){
                font-size: 14px;
                margin-bottom: 0;
            }
            &:hover,
            &.current{
                color: $brand-secondary;
            }
            &.lastLink{
                background: $brand-secondary;
                color:$brand-title !important;
                transition: background 250ms ease;
                &:hover{
                    background: black;
                    color:white !important;
                }
            }
            
        }
    }
    .socialNav{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-top: 35px;
        a{
            color:$brand-light;
            width: 40px;
            margin: 0 20px;
        }
    }
    .cma-mobile{
        .hamburger{
            span{
                background: $brand-light;
                border-radius: 2px;
                display: block;
                height: 4px;
                width: 100%;
                margin-bottom: 6px;
                transition: opacity 250ms ease, transform 250ms ease;
            }
        }
    }
    &.stick{
        background: rgba(#161616,0.8);
        backdrop-filter: blur(3px);
        .cma-logo svg path{
            fill: $brand-light !important;
        }
        nav a:not(.current,.lastLink){
            color: $brand-light !important;
        }
    }
}