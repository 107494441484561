html,body{
    margin: 0;
    padding: 0;
    min-height: 100%;
}
body{
    font-family: $fontBase;
    font-size: 16px;
    
    &.noScroll{
        .cma-menu{
            opacity: 1;
            visibility: visible;
            transition: opacity 250ms ease, visibility 250ms ease;
        }
        .hamburger{
            span{
                &:first-child{
                    transform: translate(0px, 10px) rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    transform: rotate(-45deg) translate(7px, -7px);
                }
            }
        }
    }
    &.lights{
        header{
            svg{
                width: 100%;
            }
            svg path{
                fill:$brand-light;
            }
            nav a:not(.current){
                color:$brand-light;
            }
        }
    }
    &.dark{
        svg{
            width: 100%;
        }
        svg path{
            fill:$brand-title;
        }
        nav a:not(.current){
            color:$brand-title;
        }
    }
}

picture{
    display: block;
    line-height: 0;
}

.do-overflow{
    overflow:hidden;
    position: relative;
}

.fontTitles{
    font-family: $fontTitle;
}

.container-hdr{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @include media-breakpoint-up(lg){
        padding-right: 50px;
        padding-left: 50px;
    }
}

a{
    text-decoration: none;
}

p{
    line-height: 1.5;
}

//
.dFlex{
    display: flex;
    flex-wrap: wrap;
}
.img-fluid{
    max-width: 100%;
    height: auto;
}

//ui


.cmaBtn{
    border:none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    padding: 12px 30px;
    min-height: 41px;
    min-width: 230px;
    transition: color 250ms ease, border 250ms ease, background 250ms ease;
    &-lined-secondary{
        background: $brand-secondary;
        color:$brand-primary;
       
        &.cmaBtn-icon {
            align-items: center;
            .cma-icon{
                width: 13px;
                height: 15px;
                margin-left: 10px;
                svg path{
                    fill: $brand-primary;
                }
            }
        }

        &:hover{
            background: #444444;
            color:$brand-light;
            .cma-icon svg path{
                fill: $brand-light;
            }
        }
    }
    &-secondary{
        background: $brand-secondary;
        color:$brand-primary;
        &:hover{
            background:$brand-primary;
            color:$brand-secondary;
        }
    }
}

.singleLinkLight{
    color:$brand-light;
    transition: color 250ms ease;
    &:hover{
        color:$brand-secondary;
    }
}

.smallSubHeading{
    color:$brand-title;
    font-size: 24px;
    font-weight: 300;
    display: block;
    @include media-breakpoint-up(md){
        font-size: 26px;
    }
}
.color-light{
    color: $brand-light !important;
}
.color-heading{
    color: $brand-title;
}
.color-secondary{
    color:$brand-secondary;
}
//hepers
.noPdxs{
    @include media-breakpoint-down(md){
        padding-left: 0;
        padding-right: 0;
    }
}

.px-xs{
    @include media-breakpoint-down(sm){
        padding-left: calc(8.33333333% + 15px);
        padding-right: calc(8.33333333% +  15px);
    }
}

.pdSections{
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-up(md){
        padding-top: 70px;
        padding-bottom: 70px;
    }
}
.pdBlocks{
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-up(md){
        padding-top: 90px;
        padding-bottom: 90px;
    } 
}
.mbNopad{
    @include media-breakpoint-down(sm){
        padding: 0;
    }
}

.nMb{
    margin-bottom: 0 !important;
}

.text-center{
    text-align: center;
}
.text-center-xs{
    @include media-breakpoint-down(sm){
        text-align: center;
    }
}

.text-hightlight{
    font-weight: 700;
    @include media-breakpoint-up(md){
        font-size: 20px;
    }
}
.mB5{
    margin-bottom: 50px;
    @include media-breakpoint-up(md){
        margin-bottom: 0;
    }
}
.mb5-1{
    margin-bottom: 50px;
    @include media-breakpoint-up(md){
        margin-bottom: 100px;
    }
}
.mxMb{
    @include media-breakpoint-down(md){
        margin: 0 auto;
    }
}
.row-md-reverse{
    @include media-breakpoint-up(md){
        flex-direction: row-reverse;
    }
}

//headings
h1,h2,h3,h4{
    margin: 0;
}
.heading1{
    @extend .fontTitles;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -0.3px;
    @include media-breakpoint-up(md){
        font-size: 45px;
        line-height: 1.2;
    }
    @include media-breakpoint-up(xl){
        font-size: 66px;
    }
}
.heading2{
    @extend .color-heading;
    @extend .fontTitles;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -0.3px;
    margin-bottom: 50px;
    @include media-breakpoint-up(md){
        font-size: 35px;
        line-height: 1.2;
        margin-bottom: 80px;
    }
    @include media-breakpoint-up(xl){
        font-size: 50px;
    }
}
.heading3{
    @extend .color-heading;
    @extend .fontTitles;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.3px;
    margin-bottom: 40px;
    @include media-breakpoint-up(md){
        font-size: 25px;
        line-height: 1.33;
        margin-bottom: 55px;
    }
    @include media-breakpoint-up(xl){
        font-size: 30px;
    }
}

.likeMask{
    overflow: hidden;
    position: relative;
    >*{
        display: block;
    }
}

.fullImg,
.fullImg picture img{
    width: 100%;
}

.cma-banner{
    position: relative;
    picture{
        position: relative;
        overflow:hidden;
    }
    img{
        width: 100%;
    }
    &_caption{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        
        .cma-banner_sub{
            color:#f3d331;
            font-size: 16px;
            font-weight: 300;
            display: block;
            margin-bottom: 50px;
            @include media-breakpoint-up(md){
                font-size: 22px;
                margin-bottom: 65px;
            }
        }
    }
}

@mixin centrar($direccion:"") {
    @if $direccion==vertical {
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
    }

    @else if $direccion==horizontal {
        left: 50%;
        transform: translateX(-50%);
        -ms-transform: translateX(-50%);
    }

    @else {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
}

.cma-sec_header{
    position: relative;
    picture,img{
        width: 100%;
    }
    .container{
        text-align: center;
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}