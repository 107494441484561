//Font
$fontTitle: 'Poppins', sans-serif;
$fontBase: 'Open Sans', sans-serif;


//Colors
$brand-primary:#1c1c1c;
$brand-secondary:#ffd500;
$brand-title:#2d2d2d;
$brand-light: white;
$brand-dark: #201f1f;
$brand-green2: #ffd500;
