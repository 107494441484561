.secMask{
    .likeMask {
        span,.maskInner{
            transform: translateY(100%);
            opacity: 0;
            transition: transform 1s ease, opacity 1s ease;
        }
        &:nth-child(2) span{
            transition-delay: 250ms;
        }
    }
    &-in{
        .likeMask span{
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.setMask{
    
    overflow: hidden;
    position: relative;
    .maskInnerFade{
        opacity: 0;
        transition: opacity  500ms ease;
    }
    .maskInner{
        display: inline-block;
        transform: translateY(100%);
        opacity: 0;
        transition: transform 1s ease, opacity 1s ease;
    }
    
    &-in{
        .maskInnerFade{
            opacity: 1;
        }
        .maskInner{
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.zoom{
    transform: scale(1.1);
    transition: transform 1s ease-out;
    &In{
        transform: scale(1);
    }
}

.fade{
    opacity: 0;
    transition: opacity 1s ease;
    &In{
        opacity: 1;
    }
}

.onlyDsk{
    opacity: 0;
    transition: transform 500ms ease, opacity 500ms ease;
    @include media-breakpoint-up(lg){
        transform: translateY(50px);
        &:nth-child(2){
            transition-delay: 250ms;
        }
        &:nth-child(3){
            transition-delay: 500ms;
        }
        &:nth-child(4){
            transition-delay: 750ms;
        }
    }
    &-in{
        @include media-breakpoint-up(lg){
            transform: translateY(0);
        }
        opacity: 1;
    }
}

.smartSec{

    .smartSec_elem{
        opacity: 0;
        transform: translateY(50px);
        transition: transform 300ms ease-out, opacity 300ms ease-out;
    }
    &In .smartSec_elem{
        opacity: 1;
        transform: translateY(0);
    }
    @for $i from 1 through 11 {
        &In .smartSec_elem{
          // :nth-child(1-8) 
          &:nth-child(#{$i}) {
            
            // Delay the animation. Delay increases as items loop.
            transition-delay: $i * 250ms;
          }
        }
      }
   
}

.circleMask{
    picture{
        mask-image: radial-gradient(#000 70%, transparent 70.1%);
        mask-repeat: no-repeat;
        mask-position: center center;
        mask-size: 0vmax 0vmax;
        transition: mask-size 1s ease-in-out;
        transition-delay: 0.5s;
    }
    &.doMask picture{
        mask-size: 300vmax 300vmax;
        transition-delay: 0;
    }
}