footer{
    background: $brand-primary;
    color:$brand-light;
    font-family: $fontTitle;
    font-size: 14px;
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-up(md){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .cma-ftr-adrs{
        @include media-breakpoint-down(sm){
            margin: 0 auto 50px auto;
        }
    }
    address{
        font-style: normal;
        span{
            display: block;
            font-weight: 700;
        }
        a{
            color:$brand-light;
            transition: color 250ms ease;
            &:hover{
                color:$brand-secondary;
            }
        }
    }
    .cma-ftr_google{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        img{
            &:first-child{
                width: 100px;
            }
        }
        @include media-breakpoint-up(md){
            flex-wrap: wrap;
        }
        @include media-breakpoint-up(xl){
            justify-content: flex-end;
            img{
                &:first-child{
                    width: auto;
                }
                &:last-child{
                    margin-left: 30px;
                }
            }
        }
    }
    .cma-ftr_logo{
        margin-bottom: 30px;
        text-align: center;
        @include media-breakpoint-up(lg){
            margin-bottom: 0;
            text-align: initial;
        }
    }
    .cma-ftr_logos{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        a{
            line-height: 0;
            min-width: 95px;
            margin-bottom: 30px;
            @include media-breakpoint-up(md){
                min-width: 70px;
                margin-bottom: 0;
            }
        }
    }
    .cma-ftr_social{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        @include media-breakpoint-up(lg){
            justify-content: initial;
            margin-bottom: 0;
        }
        .socialLink{
            color:$brand-light;
            width: 20px;
            margin-right: 20px;
            transition: color 250ms ease;
            &:hover{
                color:$brand-secondary;
            }
            
        }
    }
    .cma-ftr_copy{
        font-size: 12px;
        font-weight: 600;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include media-breakpoint-up(lg){
            justify-content: initial;
        }
        span{
            margin-right: 25px;
            @include media-breakpoint-up(lg){
                margin-right: 50px;
            }
        }
    }
    >.container>.row{
        margin-bottom: 50px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}