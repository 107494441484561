.is_projectList{
    
    header {
        nav a{
            color: $brand-title;
        }
        .cma-logo svg path{
            fill: $brand-dark;
        }
    }

    .cma-featured{
        padding-top: 15vh;
        background: #f4f1e7;
    }
    .cma-featured_title{
        padding-bottom: 40px;
    }
    .cma-actions_txt{
        margin-bottom: 50px;
        @include media-breakpoint-up(md){
            margin-bottom: 0;
        }
        span{
            display: block;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 30px;
            @include media-breakpoint-up(md){
                font-size: 30px;
            }
        }
    }
}


.cma-featured_item{
    margin-bottom: 50px;
    @include media-breakpoint-up(md){
        &:nth-child(3n + 2){
            .cma-featured_wrap{
                margin-top: -30px;
            }
        }
    }
    figure{
        text-align: center;
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        backface-visibility: hidden;
        mask-image: -webkit-radial-gradient(white, black);
        transform: translateY(100%);
        transition: transform 1s cubic-bezier(0.45, 0.05, 0.55, 0.95);
        img{
            position: relative;
            transform: translateY(-100%) scale(1.1);
            transition: transform 1s cubic-bezier(0.45, 0.05, 0.55, 0.95);
        }
    }
    &Caption{
        color:$brand-light;
        padding: 0;
        position: absolute;
        left: 12px;
        right: 12px;
        bottom: 20px;
        @include media-breakpoint-up(md){
            padding: 0 30px;
        }
        h3{
            font-size: 14px;
            font-weight: normal;
            @include media-breakpoint-up(md){
                margin-bottom: 10px;
                font-size: 20px;
            }
        }
        p{
            font-size: 12px;
            font-weight: 300;
            margin: 0;
            @include media-breakpoint-up(md){
                font-size: 16px;
            }
        }
    }
    &.EntryItem{
        figure{
            transform: translateY(0);
            img{
                transform: translateY(0) scale(1);
            }
        }
    }
    .cma-featured_wrap{
        display: block;
        position: relative;
        &:hover{
            figure img{
                transform: scale(1.05);
            }
        }
    }
}

/**Detail**/

.is_project{
    
    .cma-project_header{
        position: relative;
        picture,img{
            width: 100%;
        }
        .container{
            text-align: center;
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%,-50%);
            h1{
                span{
                    font-size: 26px;
                    font-weight: 300;
                    display: block;
                    text-transform: uppercase;
                }
                p{
                    margin: 0;
                }
            }
        }
    }
}

.cma-reto_logo{
    @include media-breakpoint-down(md){
        margin:0 auto 50px auto;
    }
}

.cma-detailTitle{
    font-family: $fontTitle;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    @include media-breakpoint-up(md){
        font-size: 40px;
    }
}

.cma-video{
    background: linear-gradient(to bottom, #ffffff 0%,#ffffff 50%,#f4f1e7 50%); 
    .container{
        position: relative;
        picture,img{
            width: 100%;
        }
    }
}

.strechImg,
.strechImg img{
    width: 100%;
}

.videoSingle{
    cursor: pointer;
    svg {
        transform-origin: center;
        transition: transform 250ms ease;
        path{
            fill:$brand-light;
        }
    }
    &:hover{
        svg {
            transform: scale(1.1);
        }
    }
}

.cma-weDid{
    background: #f4f1e7;
}

.cma-achieve{
    padding-bottom: 100px;
}

.cma-projectResult{
    padding-top: 70px;
    padding-bottom: 70px;
    @include media-breakpoint-up(md){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    &_title{
        @include media-breakpoint-up(md){
            padding-bottom: 100px;
        }
    }
    .cma-result_item{
        padding: 70px;
        @include media-breakpoint-only(md){
            padding: 27px;
        }
        &:first-child{
            background: #f4f1e7;
        }
        &:nth-child(2){
            background: #f4d42f;
            @include media-breakpoint-up(md){
                margin-top: -30px;
                margin-bottom: 30px;
            }
        }
        &:nth-child(3){
            background: #3e3e3e;
            color:$brand-light;
        }
        span{
            font-size: 50px;
            font-weight: bold;
            display: block;
            @include media-breakpoint-up(md){
                font-size: 50px;
            }
        }
        p{
            font-size: 22px;
            line-height: 1.18;
            margin: 0;
            @include media-breakpoint-up(md){
                font-size: 22px;
            }
        }
    }
}

.cma-projectServices{
    padding-bottom: 50px;
    @include media-breakpoint-up(md){
        padding-bottom: 100px;
    }
    &-title{
        line-height: 1.1;
    }
    &_items{
        padding-left: 50px;
        color:$brand-secondary;
        li{
            margin-bottom: 10px;
            span{
                color:$brand-title;
            }
        }
    }
}

.cma-paginator{
    padding-bottom: 70px;
    @include media-breakpoint-up(md){
        padding-bottom: 100px;
    }
    &_inner{
       
        display: flex;
        justify-content: space-between;

        .cmaBtn{
            min-width: 170px;
        }
        
    }
}