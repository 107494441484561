.is_services{
    .cma-sec_header{
        .subTitle{
            color:$brand-secondary;
            font-size: 26px;
            font-weight: 300;
            margin-bottom: 20px;
            @include media-breakpoint-up(md){
                font-size: 26px;
            }
        }
        p{
            margin: 0;
        }
        .subTitleTxt{
            color:$brand-light;
            font-size: 18px;
            line-height: 1.56;
            @include media-breakpoint-up(md){
                font-size: 18px;
            }
        }
    }
    .cma-services_stack{
        background: $brand-light;
        overflow: hidden;
        
        &.strategy{
            padding-top: 50px;
        }

        h2{
            margin-bottom: 30px;
        }
        p{
            @include media-breakpoint-up(xl){
                padding-right: 8.33333333%;
            }
        }
        figure{
            margin: 0;
            padding: 0;
            position: relative;
        }

        @include media-breakpoint-up(lg){
            min-height: 106vh;
            &:nth-child(even){
                .cma-services_stack-list{
                    right: 0;
                    left: initial;
                }
            }
        }
        &.bc
        {
            background: #f4f1e7;
            padding-top: 50px;
            @include media-breakpoint-up(md){
                padding-top: 0;
            }
        }

        @include media-breakpoint-up(xxl){
            min-height: 76vh;
        }

        &-data{
            @include media-breakpoint-down(sm){
                padding-left: 8.3333333333%;
                padding-right: 8.3333333333%;
                p{
                    margin-bottom: 50px;
                }
            }
        }

        &-img{
           
            padding: 50px 0;
            
            &::after{
                content: "";
                height: 50%;
                width: 100%;
                position: absolute;
                top: 0;
                z-index: 1;
                @include media-breakpoint-up(md){
                    height: 100%;
                }
            }
            img{
                position: relative;
                z-index: 2;
                @include media-breakpoint-down(sm){
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
            .leftImg{
                @include media-breakpoint-up(lg){
                    margin-left: -46%;
                }
                @include media-breakpoint-up(xl){
                    margin-left: -23%;
                }
                @include media-breakpoint-up(xxl){
                    margin-left: 0;
                }
            }
            &.sqbeige{
                &::after{
                    background: #f4f1e7;
                    left: 37%;
                }
            }
            &.sqblack{
                &::after{
                    background: $brand-title;
                    left: -37%;
                }
            }
            &.sqTalk{
                &::after{
                    background: $brand-title;
                    left: 37%;
                }
            }
            &.sqSite{
                &::after{
                    background: $brand-secondary;
                    left: -37%;
                }
            }
        }
        &-list{
            background: $brand-light;
            box-shadow: 41px 0px 57px 0 rgba(0, 0, 0, 0.2);
            padding: 45px 8.333333%;
            position: relative;
            left: 0;
            z-index: 2;
            @include media-breakpoint-up(md){
                padding: 45px 70px;
                position: absolute;
                top: 65%;
            }
            h3{
                color:#d2b429;
                font-size: 20px;
                font-weight: bold;
                @include media-breakpoint-up(md){
                    font-size: 20px;
                }
            }
            ul{
                color:#d2b429;
                line-height: 1.5;
                span{
                    color:#343434;
                }
            }
        }
        @include media-breakpoint-down(sm){
            &:nth-child(even) >.container >.row{
                flex-direction: column-reverse;
            }
        }
    }
    .cma-agencySeo{
        color:$brand-light;
        padding-top: 50px;
        @include media-breakpoint-down(sm){
            background: url('/images/agencia-digital-especializada-en-seo-movil.webp') no-repeat center center;
            background-size: cover;
        }
        @include media-breakpoint-up(md){
            background: url('/images/agencia-digital-especializada-en-seo.webp') no-repeat center center;
            background-size: cover;
            padding-top: 100px;
        }
        h2{
            margin-bottom: 10px;
        }
        &-txt1{
            @include media-breakpoint-up(md){
                font-size: 18px;
            }
            margin-bottom: 50px;
        }
        &-txt2{
            font-size: 18px;
            font-weight: 700;
            @include media-breakpoint-up(md){
                font-size: 20px;
            }
            margin-bottom: 50px;
        }
        .cma-cta{
            padding-bottom: 70px;
            @include media-breakpoint-up(md){
                padding-bottom: 100px;
            }
        }
    }
    .cma-collaborate{
        padding-top: 50px;
        padding-bottom: 50px;
        @include media-breakpoint-down(sm){
            //background: url('/images/equipo-especializado-colmena-interactive-movil.webp') no-repeat right top;
            .heading2{
                padding-left: 8.3333333%;
                padding-right: 8.3333333%;
            }
        }
        @include media-breakpoint-up(md){
            //background: url('/images/equipo-especializado-colmena-interactive.webp') no-repeat right top;
            padding-top: 100px;
            padding-bottom: 150px;
        }
        .cma-collaborate_item{
            background: $brand-light;
            padding: 40px 60px;
            p{
                font-size: 18px;
                line-height: 1.33;
                @include media-breakpoint-up(md){
                    font-size: 16px;
                }
            }
            .cma-icon{
                display: inline-block;
                width: 80px;
            }
            &:nth-child(odd){
                @include media-breakpoint-up(lg){
                   top: 50px;
                }
            }
            &:nth-child(even){
                @include media-breakpoint-up(lg){
                    box-shadow: -4px -0.3px 21px 0 rgba(0, 0, 0, 0.18);
                    z-index: 2;
                }
            }
            &:nth-child(1){
                background: #f4f1e7;
            }
            &:nth-child(2){
                background: #f4d42f;
                .cma-icon{
                    width: 37px;
                }
            }
            &:nth-child(3){
                background: #3e3e3e;
                color:$brand-light;
            }
        }
    }
    .cma-digital{
        background: url('/images/branding-marketing-colmena.webp') #252424 no-repeat right center;
        color:$brand-light;
        padding-top: 50px;
        padding-bottom: 50px;
        @include media-breakpoint-up(lg){
            padding-top: 100px;
            padding-bottom: 100px;
        }
        &Title{
            @include media-breakpoint-down(sm){
                padding-left: 8.333333333%;
                padding-right: 8.33333333%;
            }
        }
        &Map{
            @include media-breakpoint-down(sm){
                margin-bottom: 50px;
            }
        }
        .heading2{
            margin-bottom: 0;
        }
        p{
            margin-bottom: 50px;
        }
        
        .cma-agency_grid{
            .gridItem{
                padding-left: 25px;
                padding-right: 25px;
                figure{
                    margin: 0;
                    min-width: 80px;
                }
                p{
                    margin-bottom: 0;
                }
                &:nth-child(4){
                    grid-row:initial;
                }
            }
        }
    }
    .cma-customersTitle{
        margin-bottom: 50px;
    }
    .cma-customers{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        img{
            margin: 0 17px 35px;
            @include media-breakpoint-up(md){
                margin: 0 30px 35px;
            }
            &:nth-child(1),
            &:nth-child(12){
                @include media-breakpoint-up(lg){
                    margin-left: 6vw;
                }
            }
            &:nth-child(5),
            &:nth-child(16){
                @include media-breakpoint-up(lg){
                    margin-right: 6vw;
                }
            }
        }
    }
}