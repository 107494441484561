.bee-reel{
    .VideoReel-box{    
        position: relative;
        display: none;
        &.showme{
            height: auto;
        }
    }

    .Video-data {
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        overflow: hidden;
    }

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .videoLink {
        margin: 10px auto 0;
        position: absolute;
        left: 0;
        right: 0;
        width: 180px;
    }
    .reel-close {
        background: $brand-secondary;
        border:40px;
        color: $brand-light;
        cursor: pointer;
        position: absolute;
        bottom: 20px;
        right: 15px;
        z-index: 2;
        height: 40px;
        width: 40px;
        border-radius: 50px;
        font-size: 2em;
        line-height: 34px;
        text-align: center;
        @include media-breakpoint-up(lg){
            bottom: 100px;
        }
    }
}