.is_seo{
    .seo-important{
        padding-top: 50px;
        @include media-breakpoint-up(md){
            overflow-x: hidden;
            padding-top: 70px;
        }
        &_seo{
            padding: 60px 73px 65px 62px;
            box-shadow: 0 0 32px 0 rgba(105, 105, 105, 0.55);
            background-color: #fff;
            @include media-breakpoint-up(md){
                margin-right: -8.3333333%;
                margin-left: 8.3333333%;
                z-index: 2;
            }
        }
        &_seo-img{
            padding: 0;
        }
    }
    .seo-pillars{
        background: url('/images/3-pilares-seo-mobile.webp') no-repeat left 160px;
        @include media-breakpoint-up(md){
            background: url('/images/3-pilares-seo.webp') no-repeat center center;
            padding-bottom: 30px;
        }
        &_3{
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 0;
            margin-bottom: 115px;
            @include media-breakpoint-up(md){
                justify-content: initial;
                line-height: normal;
                margin-bottom: 0;
            }
            span{
                &:first-child{
                    font-size: 120px;
                    font-weight: bold;
                    @include media-breakpoint-up(md){
                        font-size: 153px;
                    }
                }
                &:last-child{
                    font-size: 38px;
                    font-weight: bold;
                    line-height: 1.24;
                    @include media-breakpoint-up(md){
                        font-size: 50px;
                    }
                    em{
                        color:$brand-secondary;
                        font-style: normal;
                    }
                }
            }
        }
        &_data{
            
            @include media-breakpoint-up(md){
                padding-top: 70px;
            }
            
            &-text{
                span{
                    font-size: 28px;
                    font-weight: bold;
                    display: block;
                    margin-bottom: 10px;
                    position: relative;
                    @include media-breakpoint-up(md){
                        font-size: 24px;
                    }
                    &::after{
                        content: url('/images/flecha-larga.svg');
                        display: block;
                        width: 70px;
                        height: 2;
                        position: absolute;
                        top: 50%;
                        right: 100%;
                        transform: translateY(-63%);
                        margin-right: 10px;
                        
                    }
                }
                p{
                    font-size: 14px;
                    line-height: 1.71;
                    margin:0 0 50px 0;
                }
            }
        }
    }

    .seo-web{
        
        @include media-breakpoint-up(md){
            margin-bottom: 75px;
        }
        &_data{
            @include media-breakpoint-up(md){
                padding-right: 50px;
            }
            >ul{
                color:$brand-secondary;
                padding-left: 20px;
                p{
                    color:$brand-primary;
                    line-height: 24px;
                    strong{
                        font-size: 18px;
                    }
                }
                li>ul{
                    list-style-image: url('/images/bullet-arrow.svg');
                    color:$brand-primary;
                }
            }
        }
        &_img{
            @include media-breakpoint-down(md){
                padding: 50px 0 0 0;
                line-height: 0;
            }
        }
    }

    .seo-help{
        background: #f5f1e7;
        padding-top: 50px;
        overflow-x: clip;
        @include media-breakpoint-up(md){
            padding-top: 100px;
        }
        &_data{
            counter-reset: lis;

            ul{
                margin-top: 0;
                @media (min-width:1550px){
                    padding-right: 40px;
                }
            }
            li{
                display: flex;
                margin-bottom: 30px;
                font-size: 14px;
                line-height: 24px;
                @media (min-width:1550px){
                    margin-bottom: 35px;
                }
                &:before{
                    background: $brand-secondary;
                    border-radius: 50%;
                    counter-increment: lis;
                    content: counter(lis);
                    height: 27px;
                    width: 27px;
                    display: block;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 700;
                    margin-right: 10px;
                }
                p{
                    flex: 1;
                    margin: 0;
                }
                strong{
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
        &_img{
            margin-bottom: 50px;
            @include media-breakpoint-up(md){
                margin-bottom: 0;
            }
            img{
                max-width: 100%;
                height: auto;
                @include media-breakpoint-up(lg){
                    position: absolute;
                    left: -50px;
                    width: 40vw;
                    max-width: initial;
                }
            }
        }
    }
    .seo-intervention{
        background: #222222;
        padding-top: 8%;
        padding-bottom: 50px;
        @include media-breakpoint-up(lg){
            background: url('/images/expandir-el-poder-de-tu-sitio.webp') no-repeat center center /cover;
            padding-bottom: 100px;
        }
        @media (min-width:1550px){
            padding-top: 14%;
        }
        
        h2{
            color:$brand-light;
            text-align: center;
        }
        .seo-sldr{
            margin-bottom: 70px;
            @include media-breakpoint-up(lg){
                display: flex;
                flex-wrap: wrap;
            }
            .slick-slide{
                &:nth-child(2) .item{
                    background-color: #ffd500;
                    box-shadow: 0 0 147px 0 rgba(46, 46, 46, 0.45);
                    position: relative;
                    padding: 60px 45px;
                    z-index: 1;
                    margin-top: -20px;
                    margin-bottom: 20px;
                }
            }
            .item{
                background-color: #f3efe3;
                display: flex;
                align-items: center;
                padding: 40px 30px;
                width: 33.3333333%;
                line-height: 1.63;
                height: 290px;
                @include media-breakpoint-up(lg){
                    height: auto;
                }
                &:nth-child(2){
                    background-color: #ffd500;
                    box-shadow: 0 0 147px 0 rgba(46, 46, 46, 0.45);
                    position: relative;
                    padding: 60px 45px;
                    z-index: 1;
                    margin-top: -20px;
                    margin-bottom: 20px;
                }
                p{
                    margin: 0;
                }
            }
            .slick-dots {
                margin-bottom: -20px;
                li{
                    border: 1px solid $brand-light;
                    border-radius: 50%;
                    height: 15px;
                    width: 15px;
                    &.slick-active{
                        background: $brand-light;
                    }
                    button{
                        height: inherit;
                        width: inherit;
                        &:before{
                            content: initial;
                        }
                    }
                }
            }
        }
        &_ftr{
            text-align: center;
            p{
                color:$brand-light;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 30px;
            }
        }
    }
    .seo-consultancy{
        background: #f5f1e7;
        padding-top: 50px;
        @include media-breakpoint-up(lg){
            overflow-x: clip;
            padding-top: 0;
        }
        &_data{
            line-height: 1.5;
            margin-bottom: 50px;
            @include media-breakpoint-up(lg){
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
                min-height: 625px;
                margin-bottom: 0;
            }
            @media (min-width:1550px){
                min-height: 920px;
            }
            .seo-hightlight{
                font-size: 20px;
                font-weight: bold;
                @include media-breakpoint-up(md){
                    font-size: 20px;
                }
            }
            p{
                margin:0 0 20px 0;
            }
        }
        &_img{
            
            padding: 0;
            line-height: 0;
            img{
                max-width: 100%;
                height: auto;
                @include media-breakpoint-up(lg){
                    position: absolute;
                    left: 0;
                    width: 40.5vw;
                    max-width: initial;
                }
            }
            &.imgLeft{
                img{
                    @include media-breakpoint-up(lg){
                        left: initial;
                        right: 0;
                    }
                }
            }
        }
    }
    .seo-onSite{
        background: #201f1f;
        color:$brand-light;
        @include media-breakpoint-up(lg){
            padding-top: 80px;
            padding-bottom: 120px;
        }
        strong.seo-hightlight{
            color:$brand-secondary;
            font-size: 16px;
        }
        .heading2{
            color:$brand-light;
        }
        .seo-consultancy_data{
            padding-top: 50px;
        }
        ul{
            color:$brand-secondary;
            p{
                color:$brand-light;
            }
        }
    }
    .seo-offSite{
        padding-top: 50px;

        @include media-breakpoint-up(md){
            padding-top: 75px;
            padding-bottom: 75px;
            overflow-x: clip;
        }
        &_data{
            margin-bottom: 50px;
            @include media-breakpoint-up(lg){
                min-height: 650px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 0;
            }
            @media (min-width:1550px){
                min-height: 900px;
            }
            .seo-hightlight{
                font-size: 20px;
                margin-bottom: 20px;
            }
            ul{
                li{
                    color:$brand-secondary;
                    span{
                        color:$brand-primary;
                    }
                }
            }
        }
        &_tactics{
            margin-bottom: 30px;
        }
        &_img{
            padding: 0;
            line-height: 0;
            img{
                width: 100%;
                @include media-breakpoint-up(lg){
                    width: 40vw;
                }
            }
        }
    }
    .seo-serviceType{
        background: $brand-primary;
        padding-top: 50px;
        padding-bottom: 50px;
        @include media-breakpoint-up(lg){
            background: url('/images/tipo-de-servicios.webp') no-repeat center center /cover;
            padding-top: 100px;
            padding-bottom: 100px;
        }
        .heading2{
            @include media-breakpoint-down(sm){
                text-align: center;
            }
        }
        &_grid{
            .row{
                @include media-breakpoint-up(lg){
                    padding-left: 50px;
                    padding-right: 50px;
                }
                margin-bottom: 50px;
            }
        }
        &_item:not(:empty){
            border:1px solid $brand-secondary;
            color:$brand-light;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 115px;
            margin:-1px 0 0 -1px;
            @include media-breakpoint-up(md){
                font-size: 20px;
                height: 162px;
                &:nth-child(3){
                    margin-right:25%;
                }
                &:nth-child(4){
                    margin-left: calc( 25% - 2px );
                }
            }
            
        }
    }
    .seo-complementary{
        background: #f5f1e7;
        padding-top: 50px;
        padding-bottom: 50px;
        @include media-breakpoint-up(md){
            padding-top: 80px;
        }
        &_data{
            display: flex;
            align-items: center;
            padding-top: 50px;
            @include media-breakpoint-up(md){
                padding-left: 50px;
                padding-top: 0;
            }
        }
        &_img{
            padding: 0;
            line-height: 0;
            @include media-breakpoint-up(md){
                padding: 0 15px;
            }
        }
    }
    .seo-strategy{
        font-family: $fontTitle;
        span{
            font-size: 20px;
            font-weight: 500;
            display: block;
            @include media-breakpoint-up(md){
                font-size: 22px;
            }
        }
        p{
            font-size: 30px;
            font-weight: 700;
            margin: 0 0 30px 0;
            @include media-breakpoint-up(md){
                font-size: 36px;
            }
        }
    }
}