.formBox{
    &_subjects{
        margin-bottom: 50px;
    }
    .input_g{
        position: relative;
        margin-bottom: 30px;
    }
    label{
        color:$brand-title;
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
        @include media-breakpoint-up(md){
            font-size: 16px;
        }
    }
    input,textarea,select{
        background: transparent;
        color:$brand-dark;
        border: none;
        border-bottom: 1px solid $brand-dark;
        font-family: $fontBase;
        height: 40px;
        width: 100%;
        padding: 0 10px;
        &:focus,&.danger{
            outline: none;
            border-bottom-color: rgb(238, 98, 98);
        }
    }
    textarea{
        overflow: auto;
        height: 100px;
    }
    button{
        cursor: pointer;
    }
    .customSelect{
        appearance: none;
        background: url(/images/arrow-down.svg) no-repeat 95% center;
        background-size: 10px;
    }
    .fileUpload{
        display: flex;
        align-items: center;
        .lblBtn{
            background: $brand-title;
            color:$brand-light;
            cursor: pointer;
            padding: 10px 15px;
        }
    }
    input[type="file"]{
        appearance: none;
        border: none;
    }
    .text-danger{
        color: rgb(235, 109, 109);
        font-size: 14px;
        display: block;
        padding: 5px 0;
    }
    .pdfName{
        padding-left: 10px;
    }
}